<template>
  <div class="mt-3">
    
    <Loader v-if="loading" />
    <div class="container">
      <div class="row">
        <ol class="breadcrumb mt-4">
          <li class="breadcrumb-item" active><a href="/">Home</a></li>
          <li
            class="breadcrumb-item active"
            v-for="(data, index) in breadcrumb_data"
            :key="index"
          >
            <b style="cursor: pointer;" @click="getToList(data,index)">{{ data }}</b>
          </li>
        </ol>
      </div>
    </div>


           <!---mobile view-->
    <div class="dropdown1">
          <button class="btn1 btn-primary"  @click="toggleMenu()" >
            <i class="fa-solid fa-filter"></i>  Filter</button>
            
                <h6>
                  (Showing {{ currentPage }} – {{ rows }} products of
                  {{ ProductTotal }} products)
                </h6>
              
           
            </div>
          <div class="side-accordion" 
           :style="{ display: sidebarVisible ? 'flex' : 'none'  }">
            

           <button  @click="hideSidebar()" class="hidebar btn  ">  <i
                        class="fa-solid fa-xmark"
                        style="width: 15px; height: 20px"
                      ></i></button>
            <Accordion :multiple="true" :activeIndex="[0,1,2,3]">
              <AccordionTab header="Keywords">
                <!-- <IconField iconPosition="left"> -->
                <InputText
                  v-model="keyword"
                  placeholder="Search"
                  @input="filter(2)"
                />
                <!-- </IconField> -->
              </AccordionTab>
              <AccordionTab header="Manufacturers">
                <div style="height: 200px; overflow: scroll">
                  <div
                    class="form-check"
                    v-for="(data, index) in Brands"
                    :key="index"
                  >
                    <input
                      style="height: 20px; width: 20px"
                      v-model="selectedBrands"
                      class="form-check-input"
                      type="checkbox"
                      :value="data.name"
                      id="flexCheckDefault"
                      @change="filter()"
                    />
                    <img
                      :src="data.url"
                      alt=""
                      style="width: 20%; height: 20%"
                    />
                    <label class="form-check-label" for="flexCheckDefault">{{
                      data.name
                    }}</label>
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab header="Price">
                <div class="flex justify-content-center">
                  <Slider v-model="pricevalue" range class="w-14rem" />
                </div>
                <div class="flex justify-content-center mt-3">
                  <InputText v-model.number="pricevalue[0]"/>  
                  <InputText v-model.number="pricevalue[1]" />
                </div>
                <div class="mt-1" style="text-align: right; ">
                  <button class="btn btn-primary btn-sm" @click="filter(2)">Apply</button>
                </div>
              </AccordionTab>
              <AccordionTab header="Category">
                <div style="height: 200px; overflow: scroll">
                  <div
                    class="form-check"
                    v-for="(data, index) in categories"
                    :key="index"
                  >
                    <input
                      style="height: 20px; width: 20px"
                      v-model="selectedcategories"
                      class="form-check-input"
                      type="checkbox"
                      :value="data"
                      id="flexCheckDefault"
                      @change="filter()"
                    />
                    <label class="form-check-label" for="flexCheckDefault">{{
                      data
                    }}</label>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
      
      
     



    <!--left side accordion-->
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-xl-3">
          <div class="sidemenu">
            <Accordion :multiple="true" :activeIndex="[0,1,2,3]">
              <AccordionTab header="Keywords">
                <!-- <IconField iconPosition="left"> -->
                <InputText
                  v-model="keyword"
                  placeholder="Search"
                  @input="filter(2)"
                />
                <!-- </IconField> -->
              </AccordionTab>
              <AccordionTab header="Manufacturers">
                <div style="height: 200px; overflow: scroll">
                  <div
                    class="form-check"
                    v-for="(data, index) in Brands"
                    :key="index"
                  >
                    <input
                      style="height: 20px; width: 20px"
                      v-model="selectedBrands"
                      class="form-check-input"
                      type="checkbox"
                      :value="data.name"
                      id="flexCheckDefault"
                      @change="filter()"
                    />
                    <img
                      :src="data.url"
                      alt=""
                      style="width: 20%; height: 20%"
                    />
                    <label class="form-check-label" for="flexCheckDefault">{{
                      data.name
                    }}</label>
                  </div>
                </div>
              </AccordionTab>
              <AccordionTab header="Price">
                <div class="flex justify-content-center">
                  <Slider v-model="pricevalue" range class="w-20rem" :max="100000"/>
                </div>
                <div class="flex justify-content-center mt-3">
                  <InputText v-model.number="pricevalue[0]"  />
                  <InputText v-model.number="pricevalue[1]"  />
                </div>
                <div class="mt-1" style="text-align: right; ">
                  <button class="btn btn-primary btn-sm" @click="filter(2)">Apply</button>
                </div>
              </AccordionTab>
              <AccordionTab header="Category">
                <div style="height: 200px; overflow: scroll">
                  <div
                    class="form-check"
                    v-for="(data, index) in categories"
                    :key="index"
                  >
                    <input
                      style="height: 20px; width: 20px"
                      v-model="selectedcategories"
                      class="form-check-input"
                      type="checkbox"
                      :value="data"
                      id="flexCheckDefault"
                      @change="filter()"
                    />
                    <label class="form-check-label" for="flexCheckDefault">{{
                      data
                    }}</label>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </div>
        </div>




 


        <!---right side -->
        <div class="col-md-9 col-lg-9 col-xl-9">
          <div class="container  mb-2">
            <div class="row">
              <div class="dropdown">
                <h6>
                  (Showing {{ currentPage }} – {{ rows }} products of
                  {{ ProductTotal }} products)
                </h6>
                <Dropdown
                  v-model="selectedSort"
                  :options="Sort"
                  optionLabel="name"
                  placeholder="Sort by"
                  class=" md:w-14rem"
                  @change="filter(2)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-6 col-lg-6 col-xl-6"
              v-for="(product, index) in products"
              :key="index"
            >
              <div class="card mb-3" @click="toggleContent(product)">
                <div class="row g-0">
                  <div class="col-md-4">
                    <div class="card-img">
                      <div class="col-6">
                        <Badge v-if=" product.discount && product.discount > 0" style="margin-top: -10px;color: black;" :value="product.discount + '% off'" severity="warning"></Badge>
                        <span v-else><br></span>
                      </div>
                      <!-- <div class="badge4 text-bg-warning">
                        {{ product.discount }}% off
                      </div> -->
                      <Galleria
                        v-if="product.images.length > 0"
                        :value="product.images"
                        :responsiveOptions="responsiveOptions"
                        :numVisible="1"
                        containerStyle="max-width: 640px"
                        thumbnailsPosition="bottom"
                      >
                        <template #item="slotProps">
                          <img :src="slotProps.item" style="width: 100%" />
                        </template>
                        <template #thumbnail="slotProps" >
                          <img :src="slotProps.item" style="width: 50px" />
                        </template>
                      </Galleria>
                      <!-- v-if="product.images" -->
                      <!-- <Galleria
                      v-else
                      :value="product.image"                      
                      :responsiveOptions="responsiveOptions"
                      :numVisible="3"
                      containerStyle="max-width: 640px"
                      thumbnailsPosition="left">
                    </Galleria> -->
                      <img v-else :src="product.image" style="width: 100%" />
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <div class="details-pro">
                        <h6 @click="gotoLink(product.url)">
                          {{ FormatService.FormatTitle(product.title,30) }}
                        </h6>
                        <Rating
                          class="rating"
                          v-model="product.total_ratings"
                          readonly
                          :cancel="false"
                        />
                        <div class="review-links">
                  Total Reviews : {{ product.total_review }}
                  
                </div>
                        <p>
                          <!-- <b style="font-size: 17px"> ₹{{ product.price }}</b>&nbsp;<small>M.R.P ₹1193.71 (10% off)</small> -->
                          <span
                            v-if="
                              product.actual_price < product.price ||
                              product.actual_price > product.price
                            "
                          >
                            <b>
                              ₹
                              {{ FormatService.FormatAmount(product.price) }}/- </b
                            >
                            <s style="font-weight: 100; font-size: 12px"
                              >M.R.P ₹
                              {{
                                FormatService.FormatAmount(product.actual_price)
                              }}/- </s
                            >
                            ( {{ product.discount }}% off )</span
                          >
                          <b v-else>
                            ₹ {{ FormatService.FormatAmount(product.price) }}/-</b
                          >
                        </p>
                        <p class="custname ml-2">
                          by
                          <span
                            class="text-primary"
                            v-if="product.site_master.length > 0"
                            >{{
                              product.site_master[0]["name"]
                            }}</span
                          >
                        </p>
                        <p class="card-text">
                          <small class="text-body-secondary">{{
                            product.model
                          }}</small>
                        </p>
                        
                      </div>
                      <br />
                      <!--show hide button-->
                      <div id="drop" class="button1">
                        <button
                          class="btn btn-primary btn-sm"
                          @click="toggleContent(product)"
                        >
                          View Details</button
                        >&nbsp;
                        <button
                          class="btn btn-primary btn-sm"
                          @click="gotoLink(product.url)"
                        >
                          Buy Now
                        </button
                        >&nbsp;
                        <div v-if="showContent">
                          <p style="justify-content: start">
                            {{ product.description }}
                          </p>
                        </div>
                        <button v-if="product.compare_list.length>0"
                          class="btn btn-primary btn-sm"
                          @click="view_deals(index)"
                          
                        >
                          View Deals ({{ product.compare_list.length }})
                        </button>
                        <!-- {{ product.compare_list }} -->
                        </div>
                        <div>
                        
                      </div>
                      <!--show hide button end-->
                    </div>
                  </div>
                </div>
                <table class="table_deals" border="1" style="padding:10px;display:none;max-height: 150" >
                  <!-- <caption style="caption-side: top;font-size:20px; font-weight: bold; padding: 10px;">Other Deals</caption> -->
                          
                          <tr v-for="(compaire_list,index1 ) in product.compare_list" :key="index1">
                            <td>
                              
                              <img :src="compaire_list.image" style="width : 50px; height: 50px ">
                            </td>
                            <td>
                              {{ FormatService.FormatTitle(compaire_list.title,20) }}
                              <Rating
                          class="rating"
                          v-model="product.total_ratings"
                          readonly
                          :cancel="false"
                        />
                        <div class="review-links">
                  Total Reviews : {{ product.total_review }}
                  
                </div>
                            </td>
                            <td>
                              ₹ {{ FormatService.FormatAmount(compaire_list.price) }}/-
                            </td>
                            <td>
                              by
                              <span
                                class="text-primary"
                                v-if="compaire_list.site_master"
                                >{{
                                  compaire_list.site_master[0]["name"]
                                }}</span
                              >
                            </td>
                            <td>
                              <button
                          class="btn btn-primary btn-sm"
                          @click="toggleContent(compaire_list)"
                        >
                          View</button
                        >
                            </td>
                            <td>
                              <button
                          class="btn btn-primary btn-sm"
                          @click="gotoLink(compaire_list.url)"
                        >
                          Buy
                        </button
                        >
                        
                            </td>
                          </tr>
                        </table>
                      
              </div>
              
            </div>
          </div>
          <div class="pageno">
            <!-- <Paginator :rows="10" :totalRecords="ProductTotal" :rowsPerPageOptions="[10, 20, 30]" @page="onPageChange"></Paginator> -->
            <Paginator
              :rows="rows"
              :totalRecords="ProductTotal"
              :rowsPerPageOptions="[10, 50, 100]"
              @page="onPageChange"
            ></Paginator>
          </div>
          <AdSense />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apis from "@/apis";
import Loader from "../components/Loader.vue";
import FormatService from "../service/FormatService";
import AdSense from "../components/AdSense.vue";
export default {
  props: {
    filters: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      breadcrumb_data: [],
      parsedFilters: [],
      loading: false,
      keyword: "",
      selectedBrands: [],
      Brands: [],
      categories: [],
      selectedcategories: [],
      selectedType: [],
      rows: 10,
      currentPage: 1,
      ProductTotal: 0,
      FormatService: null,
      first: [0],
      pricevalue: [0, 100000],
      selectedSort: "",
      sidebarVisible: false,
    

      Sort: [
        { name: "Sort by popularity", value: "1" },
        //{ name: "Sort by latest"    ,value:"2" },
        { name: "Sort by Price:low to high", value: "3" },
        { name: "Sort by Price:high to low", value: "4" },
        { name: "Sort by Reviews", value: "5" },
        { name: "Sort by Discount", value: "6" },
      ],
      showContent: false,
      products: [
        // {
        //   title:
        //     "HP 15S-EQ2144AU Laptop (Ryzen 5 5500U/8GB RAM/512GB SSD/AMD Radeon Graphics/15.6 Inch FHD Display/Windows 11/Sliver)",
        //   price: 1025,
        //   rating: 4.5,
        //   badge: "new",
        // },
        // Add more products as needed
      ],
      responsiveOptions: [
        {
          breakpoint: "1300px",
          numVisible: 4,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
        },
      ],
    };
  },
  methods: {
    view_deals(index)
    {
      $('.table_deals').eq(index).show();
    },
    toggleMenu() {
      this.sidebarVisible = !this.sidebarVisible;
      
    },

    hideSidebar() {
      // Hide the sidebar
      this.sidebarVisible = false;

    },

    onPageChange(event) {
      console.log(event);
      this.currentPage = event.page + 1;
      this.rows = event.rows;
      $('.table_deals').hide();
      this.GetList();
    },
    toggleContent(product) {
      this.$router.push({
        name: "pphprodetails",
        params: { id: product._id, title: product.title },
      });
    },
    async filter(flag) {
      this.currentPage = 1;
      this.rows = 10;
      if (flag==2) {
          await this.GetList();
          await this.GetCount();
      }
      else
      {
        this.$emit("onFilter", {
        brand: this.selectedBrands,
        product_type: this.selectedcategories,
        // type: this.selectedType,
        search: this.keyword,
      });
      }
      
     
    },
    async GetList() {
      $('.table_deals').hide();
      var sort = this.selectedSort["value"];
      var selectedcategories = [];
      var selectedBrands = [];
      var selectedType =[];
      if (this.selectedcategories.length>0) 
      {
        for (let index = 0; index < this.selectedcategories.length; index++) 
        {
          selectedcategories.push(this.selectedcategories[index].toUpperCase());
        }
      }
      if (this.selectedBrands.length>0) 
      {
        for (let index = 0; index < this.selectedBrands.length; index++) 
        {
          selectedBrands.push(this.selectedBrands[index].toUpperCase());
        }
      }
      if (this.selectedType.length>0) 
      {
        for (let index = 0; index < this.selectedType.length; index++) 
        {
          selectedType.push(this.selectedType[index].toUpperCase());
        }
      }
      var data = {
        count: false,
        limit: this.rows,
        page_no: this.currentPage,
        brand_array: selectedBrands,
        pricefrom: this.pricevalue[0],
        priceto: this.pricevalue[1],
        search: this.keyword,
        product_type_array: selectedcategories,
        type_array: selectedType,
        sort: sort,
      };
      this.loading = true;
      var promise = apis.GetProductList(data);
      promise.then((response) => {
        this.loading = false;
        this.products = response.data.data;
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional: smooth scrolling animation
        });
        // for (let index = 0; index < this.products.length; index++)
        // {
        //     this.products[index].images.unshift(this.products[index].image);
        // }
      });
    },
    async GetCount() {
      var sort = this.selectedSort["value"];
      var selectedcategories = [];
      var selectedBrands = [];
      var selectedType =[];
      if (this.selectedcategories.length>0) 
      {
        for (let index = 0; index < this.selectedcategories.length; index++) 
        {
          selectedcategories.push(this.selectedcategories[index].toUpperCase());
        }
      }
      if (this.selectedBrands.length>0) 
      {
        for (let index = 0; index < this.selectedBrands.length; index++) 
        {
          selectedBrands.push(this.selectedBrands[index].toUpperCase());
        }
      }
      if (this.selectedType.length>0) 
      {
        for (let index = 0; index < this.selectedType.length; index++) 
        {
          selectedType.push(this.selectedType[index].toUpperCase());
        }
      }

      var data = {
        count: true,
        brand_array: selectedBrands,
        pricefrom: this.pricevalue[0],
        priceto: this.pricevalue[1],
        search: this.keyword,
        product_type_array: selectedcategories,
        type_array: selectedType,
        sort: sort,
      };
      var promise = apis.GetProductList(data);
      promise.then((response) => {
        this.ProductTotal = response.data.data;
      });
    },
    async GetShopBrands() {
      var data = { count: false };
      var promise = apis.GetBrandsList(data);
      promise.then((response) => {
        this.Brands = response.data.data;
      });
    },
    async GetCategories() {
      var data = { count: false };
      var promise = apis.GetCategoriesList(data);
      promise.then((response) => {
        this.categories = response.data.data;
      });
    },
    gotoLink(link) {
      window.open(link);
    },
    async GetFilters() {
      //console.log("this.$route.query.filters",this.$route.query.filters);
      //const filters = this.$route.query.filters;
      const filters = this.filters;
      if (filters) {
        this.parsedFilters = JSON.parse(filters);
      }
      //console.log("🚀 ~ this.parsedFilters:", this.parsedFilters)
      this.breadcrumb_data = [];
      if (this.parsedFilters.type) {
        this.selectedType = this.parsedFilters.type;
        //this.breadcrumb_data.push(this.parsedFilters.type[0]);
      }
      if (this.parsedFilters.product_type) {
        this.selectedcategories = this.parsedFilters.product_type;
        this.breadcrumb_data.push(this.parsedFilters.product_type[0]);
      }
      if (this.parsedFilters.brand) {
        this.selectedBrands = this.parsedFilters.brand;
        this.breadcrumb_data.push(this.parsedFilters.brand[0]);
      }
      if (this.parsedFilters.search) {
        this.keyword = this.parsedFilters.search;
        this.breadcrumb_data.push(this.parsedFilters.search);
      }
      await this.GetList(1);
      await this.GetCount(1);
    },
  },
  components: {
    //selectedCity, cities, isVisible, products, toggleElement
    Loader,
    AdSense
  },
  async created() {
    this.FormatService = new FormatService();
    await this.GetCategories();
    await this.GetShopBrands();
    await this.GetFilters();
  },
  watch: {},
};
</script>
<style scoped>
.breadcrumb {
  display: flex;
}
#myDIV {
  /* width: 100%;
    padding: 50px 0;
    text-align: center;
    background-color: lightblue; */
  margin-top: 20px;
}
.btn-search {
  background-color: #2e3192;
  color: #fff;
  width: 120px;
  height: 50px;
  border-radius: 10px;
  border: none;
}
.search {
  border-radius: 15px;
  background-color: #eee;
  border: white;
  height: 30px;
  width: 150px;
  padding: 1rem;
  margin-right: 1rem;
}
.badge4 {
  z-index: 99999;
  display: inline-block;
  background-color: #ff8551;
  padding: 5px;
  /* position: fixed; */
  align-items: start;
  /* text-align: start; */
  top: 0;
  width: 50px;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.75rem;
  margin-top: 18px;
}
.rating {
  color: #ff8551 !important;
}
.form-check {
  line-height: 28px;
}
.form-check .form-check-input {
  margin-top: 7px;
}
/* Support */
.support-box {
  top: 2rem;
  position: relative;
  bottom: 0;
  text-align: center;
  display: block;
}
.b-btn {
  color: white;
  text-decoration: none;
  font-weight: bold;
}
.b-btn.paypal i {
  color: blue;
}
.b-btn:hover {
  text-decoration: none;
  font-weight: bold;
}
.b-btn i {
  font-size: 20px;
  color: yellow;
  margin-top: 2rem;
}
.dropdown {
  margin-right: 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.dropdown h6 {
  margin-right: 1.75rem;
  display: flex;
}
.pageno {
  display: flex;
  justify-content: center;
}
.p-inputtext {
  width: 100% !important;
}
.dropdown1{
  display: none;
}
.side-accordion{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  z-index: 9999;
  backdrop-filter: blur(10px);
  background-color: #efefef;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 12px;
  padding-top: 30px;
  transition: 0.5s;
  line-height: 31px;
  overflow-y: scroll;
}


.btn1{
  display: none;
  background-color: #2e3192;
  color:#fff;
  border-radius: 5px;
  border:none;
  width:80px;
  height:30px;
  margin-left: 0.80rem;
}
@media only screen and (max-width: 768px) {
  /* .breadcrumb {
    margin-left: 20px;
  } */

.button1{
  font-size:10px;
}

  /* .dropdown h6{
    width: 261px;
    margin-left: 5rem;
} */
 .sidemenu{
    display:block;
  }


}

@media screen and (max-width: 600px){
  .sidemenu{
    display:none;
  }

 .p-component{
  width: 93%;
 }
  .side-accordion{
    width:80%;
  }
  .btn1{
    display: block;


    
  }
  .breadcrumb {
    font-size:13px;
    padding-left: 20px;
  }

 .dropdown{
display: block;
 }
.details-pro{
  display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}
 
.button1{
  justify-content: center;
  display: flex;
}


 .dropdown h6{
display: none;
 }

 .dropdown1{
display: flex;

   
    align-items: center;
}

.dropdown1 h6{
  padding-left: 45px;
}


 .dropdown h6{
  /* padding-top:1rem; */
  width: auto;
padding-left: 30%;
 }
  }

</style>
