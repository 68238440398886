<template>
    <div>
      <ins class="adsbygoogle"
           style="display:block"
           data-ad-client="ca-pub-1812041019524792"
           data-ad-slot="8965722898"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  </template>
  
  <script>
  export default {
    name: "AdSense",
    mounted() {
      if (window.adsbygoogle) {
        console.log("window.adsbygoogle",window.adsbygoogle);
      
        window.adsbygoogle.push({});
      }
    },
  };
  </script>
  
  <style scoped>
  /* Optional: Add styling for ad container if needed */
  </style>
  