<template>
    <div>
      <Navigation></Navigation>
      <megamenu @onFilter="handleChildFunctionCalled"></megamenu>
      <prodetails :id="id" :title="title" :key="componentKey"></prodetails>
      <AdSense />
      <pphfooter></pphfooter>
     
    </div>
  </template>
  
  <script>
  import Navigation from "../components/pphmenu.vue";
  import megamenu from "../components/pphmegamenu.vue";
  import prodetails from "../components/pphprodetails.vue"
  import AdSense from "../components/AdSense.vue";
  
  import pphfooter from "../components/pph_footer.vue";
  
  export default {
    name: "pphlisting",
    data() {
      return {
        componentKey: 0,
          id:"",
          title:"",
          filters:{}
      };
    },
    components: {
      AdSense,
      Navigation,
      megamenu,
      prodetails,
      pphfooter,
    },
    methods: {
      async handleChildFunctionCalled(data) 
    {
      await this.GoToListWithFilter(data.brand, data.product_type, data.type, data.search);
    },
    async GoToListWithFilter(brand="", product_type = "", type = "", search = "") 
    {
     
      if (brand) {
        if (this.filters.brand) {
          this.filters.brand.push(brand);
        } else {
          this.filters.brand = [];
          this.filters.brand.push(brand);
        }
      }
      if (type) 
      {

        if (this.filters.type) 
        { 
          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
          
        } 
        else 
        {
          this.filters.type = [];

          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
        }
      }
      if (product_type) {
        // if (this.filters.product_type) {
        //   this.filters.product_type.push(product_type);
        // } else {
        //   this.filters.product_type = [];
        //   this.filters.product_type.push(product_type);
        // }
        if (this.filters.product_type) 
        { 
          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
          
        } 
        else 
        {
          this.filters.product_type = [];

          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
        }
      }
      if (search) {
        this.filters.search = search;
        this.title = search;
      }

      await this.refresh_component();

      this.$router.push({
        name: "pph_listing",
        query: { filters: JSON.stringify(this.filters) },
      });
    
    },
    async refresh_component()
    {
      this.componentKey += 1;
    }
    },
    created() {
    this.id = this.$route.params.id;
    this.title = this.$route.params.title;
    // You can now pass this ID to the ChildComponent or any other child components.
    },
  };
  </script>
  