<template>
  <div>
    <Navigation></Navigation>
    <megamenu @onFilter="handleChildFunctionCalled"></megamenu>
    <Alllisting @onFilter="handleChildFunctionCalled" :filters="filter_data" :key="componentKey"></Alllisting>
    <AdSense />
    <pphfooter></pphfooter>
  </div>
</template>

<script>
import Navigation from "../components/pphmenu.vue";
import AdSense from "../components/AdSense.vue";
import megamenu from "../components/pphmegamenu.vue";
import Alllisting from "../components/pphAllListing.vue"
import pphfooter from "../components/pph_footer.vue";

export default {
  props: {},
  name: "pphlisting",
  data() {
    return {
      componentKey: 0,
      filter_data: {},
      filters:{}
    };
  },
  components: {
    AdSense,
    Navigation,
    megamenu,
    Alllisting,
    pphfooter,
  },
  created()
  { 
    this.filter_data = this.$route.query.filters;
  },
  methods:{
    
    async handleChildFunctionCalled(data) 
    { 
      await this.GoToListWithFilter(data.brand, data.product_type, data.type, data.search);
    },
    async GoToListWithFilter(brand="", product_type = "", type = "", search = "") 
    {
      this.filters={};
      // if (brand) {
      //   if (this.filters.brand) {
      //     this.filters.brand.push(brand);
      //   } else {
      //     this.filters.brand = [];
      //     this.filters.brand.push(brand);
      //   }
      // }
      if (brand) 
      {

        if (this.filters.brand) 
        { 
          if (typeof brand === 'string') 
          {
            this.filters.brand.push(brand);
          } else 
          {
            this.filters.brand = brand;
          }
          
        } 
        else 
        {
          this.filters.brand = [];

          if (typeof brand === 'string') 
          {
            this.filters.brand.push(brand);
          } else 
          {
            this.filters.brand = brand;
          }
        }
      }
      if (type) 
      {

        if (this.filters.type) 
        { 
          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
          
        } 
        else 
        {
          this.filters.type = [];

          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
        }
      }
      if (product_type) {
        // if (this.filters.product_type) {
        //   this.filters.product_type.push(product_type);
        // } else {
        //   this.filters.product_type = [];
        //   this.filters.product_type.push(product_type);
        // }
        if (this.filters.product_type) 
        { 
          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
          
        } 
        else 
        {
          this.filters.product_type = [];

          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
        }
      }
      if (search) {
        this.filters.search = search;
      }

      this.filter_data = JSON.stringify(this.filters);
      await this.refresh_component();

      
      this.$router.push({
        name: "pph_listing",
        query: { filters: JSON.stringify(this.filters) },
      });

    },
    async refresh_component()
    {
      this.componentKey += 1;
    }
  }
};
</script>
