<template>
  <div>
    <Navigation></Navigation>
    <AdSense />
    <megamenu @onFilter="handleChildFunctionCalled"></megamenu>
    <div class="container">
      <div class="col-12">
        <div class="card">
          <h3 style="text-align: center"><b>About Us</b></h3>
          <p>
            Welcome to
            <a href="https://pcparthunt.com">https://pcparthunt.com</a>, your
            ultimate destination for comparing prices and finding the best deals
            on PC parts from major Indian retailers. At [Website Name], we
            understand the challenges faced by enthusiasts, gamers, and
            professionals when it comes to building or upgrading their PCs.
            That's why we've created a platform that simplifies the process of
            finding the right components at the best prices. Our Mission Our
            mission at
            <a href="https://pcparthunt.com">https://pcparthunt.com</a> is to
            empower consumers with the tools and information they need to make
            informed purchasing decisions. We believe that everyone should have
            access to affordable and high-quality PC components, regardless of
            their budget or technical expertise. By providing comprehensive
            price comparisons, detailed product information, and user-generated
            reviews, we aim to make the PC building experience more accessible
            and enjoyable for everyone.
          </p>
          <b>What We Offer</b>
          <ul>
            <li>
              Comprehensive Price Comparison: We aggregate prices from leading
              Indian retailers such as Amazon.in, MDComputer, Flipkart, Vedant
              Computer, and Prime ABGB, allowing you to compare prices and find
              the best deals in one convenient location.
            </li>
            <li>
              Real-Time Updates: Our platform is constantly updated with the
              latest prices and product listings, ensuring that you always have
              access to accurate and up-to-date information.
            </li>
            <li>
              User-Friendly Interface: With our intuitive interface and advanced
              filtering options, finding the perfect PC parts for your needs has
              never been easier. Whether you're a novice or an experienced
              builder, our website is designed to cater to users of all skill
              levels.
            </li>
            <li>
              Benchmarking Tools: Make informed decisions with our benchmarking
              tools, which provide detailed performance metrics and analysis for
              each product. Compare multiple components side by side to
              determine which ones offer the best value for your specific
              requirements.
            </li>
            <li>
              Reviews and Recommendations: Benefit from user-generated reviews,
              expert opinions, and personalized recommendations to ensure that
              you're purchasing products that meet your expectations in terms of
              performance, reliability, and value for money.
            </li>
          </ul>
          <b>Get in Touch</b>
          <p>
            Have questions, feedback, or suggestions? We'd love to hear from you! 
            <!-- Feel free to reach out to us via our [Contact Page] or connect with us on social media. -->
            Your input helps us improve our platform
            and better serve the needs of our users. Thank you for choosing
            <a href="https://pcparthunt.com">https://pcparthunt.com</a> for all
            your PC building needs. Happy shopping!
          </p>
        </div>
      </div>
    </div>
    <pphfooter></pphfooter>
  </div>
</template>
<script>
import Navigation from "../components/pphmenu.vue";
import megamenu from "../components/pphmegamenu.vue";
import pphfooter from "../components/pph_footer.vue";
import AdSense from "../components/AdSense.vue";
export default {
  components: {
    megamenu,
    Navigation,
    pphfooter,
    AdSense
  },
  name: "PPHAbout",
  data() {
    return {
      filters:{}
    };
  },
  mounted() {},
  methods: {
    async handleChildFunctionCalled(data) 
    {
      await this.GoToListWithFilter(data.brand, data.product_type, data.type, data.search);
    },
    async GoToListWithFilter(brand="", product_type = "", type = "", search = "") 
    {
     
      if (brand) {
        if (this.filters.brand) {
          this.filters.brand.push(brand);
        } else {
          this.filters.brand = [];
          this.filters.brand.push(brand);
        }
      }
      if (type) 
      {

        if (this.filters.type) 
        { 
          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
          
        } 
        else 
        {
          this.filters.type = [];

          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
        }
      }
      if (product_type) 
      {
        // if (this.filters.product_type) {
        //   this.filters.product_type.push(product_type);
        // } else {
        //   this.filters.product_type = [];
        //   this.filters.product_type.push(product_type);
        // }
        if (this.filters.product_type) 
        { 
          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
          
        } 
        else 
        {
          this.filters.product_type = [];

          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
        }
        
      }
      if (search) {
        this.filters.search = search;
      }
      this.$router.push({
        name: "pph_listing",
        query: { filters: JSON.stringify(this.filters) },
      });
    
    },
  },
};
</script>
<style scoped>
.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}
</style>