<template>
  <div>
    <Navigation></Navigation>
    <megamenu @onFilter="handleChildFunctionCalled"></megamenu>
    <test_comp></test_comp>
    <cards @onFilter="handleChildFunctionCalled"></cards>
    <categories></categories>
    <diffshop @onFilter="handleChildFunctionCalled"></diffshop>
    <disitem></disitem>
    <adbanner></adbanner>
    <popsearch></popsearch>
    <AdSense />
    <shopbrand></shopbrand>
    <pphfooter></pphfooter>
  </div>
</template>
<script>
import Navigation from "../components/pphmenu.vue";
import megamenu from "../components/pphmegamenu.vue";
import test_comp from "../components/pphcarousel_banner.vue";
import cards from "../components/pphscrollcart.vue";
import categories from "../components/pphcategories.vue";
import shopbrand from "../components/pphshopbrand.vue";
import disitem from "../components/pphdiscountitems.vue";
import adbanner from "../components/pphAdbanner.vue";
import popsearch from "../components/popularsearch.vue";
import diffshop from "../components/pphshop_Options.vue";
import pphfooter from "../components/pph_footer.vue";
import AdSense from "../components/AdSense.vue";
export default {
  name: "PPHPphHome",
  data() {
    return {
      filters:{}
    };
  },
  components: {
    AdSense,
    megamenu,
    Navigation,
    test_comp,
    cards,
    categories,
    adbanner,
    diffshop,
    disitem,
    popsearch,
    shopbrand,
    pphfooter,
  },
  mounted() {},
  methods: {
    async handleChildFunctionCalled(data) 
    {
      await this.GoToListWithFilter(data.brand, data.product_type, data.type, data.search);
    },
    async GoToListWithFilter(brand="", product_type = "", type = "", search = "") 
    {
     
      if (brand) {
        if (this.filters.brand) {
          this.filters.brand.push(brand);
        } else {
          this.filters.brand = [];
          this.filters.brand.push(brand);
        }
      }
      if (type) 
      {

        if (this.filters.type) 
        { 
          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
          
        } 
        else 
        {
          this.filters.type = [];

          if (typeof type === 'string') 
          {
            this.filters.type.push(type);
          } else 
          {
            this.filters.type = type;
          }
        }
      }
      if (product_type) 
      {
        // if (this.filters.product_type) {
        //   this.filters.product_type.push(product_type);
        // } else {
        //   this.filters.product_type = [];
        //   this.filters.product_type.push(product_type);
        // }
        if (this.filters.product_type) 
        { 
          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
          
        } 
        else 
        {
          this.filters.product_type = [];

          if (typeof product_type === 'string') 
          {
            this.filters.product_type.push(product_type);
          } else 
          {
            this.filters.product_type = product_type;
          }
        }
        
      }
      if (search) {
        this.filters.search = search;
      }
      this.$router.push({
        name: "pph_listing",
        query: { filters: JSON.stringify(this.filters) },
      });
    
    },
  },
};
</script>
<style></style>
